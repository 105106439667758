h4 {
  width: 100%;
  height: 2rem;
  margin-top: 10px;
  display: flex;
  align-items: center;
  color: black;
  background: #eee;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.tutor-row:hover  td {

background: #eee;
}

